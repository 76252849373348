/* You can add global styles to this file, and also import other style files */
@import 'react-big-calendar/lib/sass/styles';
// @import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
@import 'bootstrap-daterangepicker/daterangepicker.css';
@import 'sweetalert2/dist/sweetalert2.min.css';
@import 'semantic-ui-css/semantic.min.css';

body {
  font-family: 'Public Sans', sans-serif;
}

// Hide Arrows From Input Number
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.scroll-horizontal-slots {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
}

.divider {
  margin: 0 !important;
}

.slot-button-selected {
  color: #fff !important;
  background-color: #595cd9 !important;
  border-color: #595cd9 !important;
}

.active-tab {
  background-color: #696cff;
  color: #fff;
  box-shadow: 0 0.1875rem 0.375rem 0 rgb(105 108 255 / 40%);
}

.active-tab-text {
  background-color: #fff;
  color: #696cff;
  box-shadow: 0 0.1875rem 0.375rem 0 rgb(105 108 255 / 40%);
}

.daterangepicker {
  right: auto !important;
  left: 400px !important;
  overflow: scroll !important;
}

@media (max-width: 1050px) {
  .daterangepicker {
    width: 300px;
    height: 300px;
    flex-wrap: wrap;
    padding-bottom: 20px;
    left: 60% !important;
    overflow: scroll !important;
  }

  .daterangepicker .ranges {
    float: none !important;
  }

  .daterangepicker .ranges ul {
    width: auto;
  }

  .daterangepicker .drp-calendar {
    max-width: none;
    float: none;
  }
}

@media (max-width: 750px) {
  .daterangepicker {
    left: 50% !important;
  }
}

@media (max-width: 650px) {
  .daterangepicker {
    left: 40% !important;
  }
}

@media (max-width: 550px) {
  .daterangepicker {
    left: 30% !important;
  }
}

@media (max-width: 450px) {
  .daterangepicker {
    left: auto !important;
  }
}

// .daterangepicker .discount {
//   overflow: hidden !important;
//     top: 0px !important;
// }

.icon-line-height .bx {
  line-height: 0.3 !important;
}

.rounded-plus {
  border: 1px solid;
  border-radius: 100px;
}

@media (max-width: 575px) {
  .dropdown-form .form-select {
    // width: 230px;
    text-align: center;
  }

  .dataTables_length.dropdown-form {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .search-box-filter-res {
    padding: 10px;
  }

  .search-box-filter-res-dt {
    padding: 10px;
    width: 250px;
  }

  .export-btn-width {
    width: 230px !important;
  }

  .search-box-filter-res .dt-button {
    margin-right: 10px;
  }

  .clien.dt-button {
    margin-top: 20px !important;
  }
}

.topnav-bar {

  @media only screen and (min-width: 576px) {}

  @media only screen and (min-width: 576px) {}
}

.overlay {
  position: fixed;
  /* Sit on top of the page content */
  /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  /* Black background with opacity */
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
}

.loader-holder {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.scroll {
  height: 450px;
  overflow-y: auto !important;

  @media only screen and (min-width: 1536) {
    height: 460px;
  }

  @media only screen and (min-width: 1920px) {
    height: 515px;
  }
}

.scroll::-webkit-scrollbar {
  //  width: 12px;
}

.scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

body ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px !important;
  height: 10px !important;
}

.react-tel-input .form-control {
  width: 100% !important;
}

.product-item:hover {
  background: #e7e7ff;
  border-radius: 5px;
  cursor: pointer;
}

// .sale-item-checkout:hover {
//   background: #e7e7ff;
//   border-radius: 5px;
//   cursor: pointer;
//   padding: 5px !important;
// }
// .sidebaricon {
//   width: 38px !important;
//   left: 15rem !important;
//   &.collapsed {
//     left: 4rem !important;
//     color: #6a6cff;
//   }
// }

// .ar-sidebaricon {
//   width: 38px !important;
//   right: 15rem !important;
//   &.collapsed {
//     right: 4rem !important;
//   }
// }
.input-search {
  margin-top: 1.5rem;

  & input.form-control {
    padding: 0.5rem 0.5rem;
  }
}

.title-tertiary {
  color: #566a7f;
  font-size: 0.9375rem;
}

.btn-full-width {
  width: -webkit-fill-available;
}

.dropdown-menu.show>li:hover,
.dropdown-menu.show>li:active,
.dropdown-menu.show>li:focus,
.dropdown-menu.show>.dropdown-item:hover,
.dropdown-menu.show>.dropdown-item:active,
.dropdown-menu.show>.dropdown-item:focus {
  cursor: pointer;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.lang-item {
  margin-right: 5px;
  margin-left: 5px;
}

.bg-white {
  background-color: #fff !important;
}

.download-link {
  color: rgb(95, 97, 230);
  border: 1px dotted rgb(95, 97, 230);
  padding: 5px;
  border-radius: 8px;
}

.delete-image {
  color: #ff0000;
  padding: 5px;
  font-size: 18px;
}

.width-250px {
  width: 250px !important;
}

.icon-delete {
  top: 8px;
  transform: scale(0.75);
  transform-origin: 20% 0;
  position: relative;
  border-radius: 100px;
  border: 1px solid red;
}

/*------------------------------------------*/

/*	  gloabl |
/*------------------------------------------*/
.line-height-1 {
  line-height: 1 !important;
}

.on-hover {
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    cursor: pointer;
  }
}

.vh-25 {
  height: 26vh;
}

.scroll-items {
  overflow-x: hidden;
  overflow-y: auto;
}

/*------------------------------------------*/

/*	  layout | -g
/*------------------------------------------*/
.layout-menu-fixed .layout-menu {
  position: fixed; //default
}

.layout-navbar-fixed .layout-navbar {
  position: relative;
}

/*------------------------------------------*/

/*	  Arabic | -g
/*------------------------------------------*/
.rtl {
  .new-package-space {
    .ui.search.dropdown>input.search {
      position: absolute;
    }

    .ui.label>.close.icon,
    .ui.label>.delete.icon {
      margin-left: 0;
      margin-right: 0.5em;
    }
  }

  .bs-stepper-header .bs-stepper-label {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }

  .bx.bx-chevron-left,
  .bx.bx-chevron-right {
    transform: rotate(180deg);
  }

  .ScheduleInfo {
    .form-check .form-check-input {
      margin-left: 8px;
    }
  }

  .content-wrapper {
    & .select-all #selectall {
      margin-left: 10px;
    }
  }
}

.date-picker-item {
  margin: 0 18px
}

.left-84 {
  padding-left: 12px !important;
}

// demo
.input-group {
  & .ui.multiple {
    &.dropdown>.text {
      position: relative;
    }

    &.search.dropdown>.text,
    &.selection.dropdown>.divider {
      top: 6px;
      left: 8px;
    }

    &.search.dropdown>input.search {
      width: 2px;
    }
  }

  & #coverage-areas {
    &.ui.multiple.selection.dropdown {
      padding-top: 0;
      padding-bottom: 0;

      &>.divider {
        left: 0;
        top: 6px;
        padding-left: 0;
      }
    }
  }
}

.ui.multiple {
  &.selection.scrolling.dropdown {
    &>input {
      width: 2px;
    }

    &>.divider {
      left: 0;
      position: relative;
    }
  }
}

.react-datepicker__input-container {
  input {
    border: none !important;
    outline: none !important;
  }

  input:focus,
  input:focus-visible {
    /* Apply the border style when input is focused or focused and visible */
    border: none !important;
  }
}

.react-datepicker__header {
  border-bottom: none !important;
  background-color: white !important;
}

.react-datepicker__day {
  color: #697a8d !important;
}

.react-datepicker {
  button {
    background-color: rgba(67, 89, 113, 0.1) !important;
    width: 1.2rem !important;
    height: 1.2rem !important;
    margin: 0 20px;
    top: 0.7rem;
  }
}

.react-datepicker__navigation-icon--previous {
  top: 0px !important;
  right: 1px !important;
}

.react-datepicker__navigation-icon--next {
  top: 0px !important;
  left: 1px !important;
}

.react-datepicker__day--selected {
  background-color: #696cff !important;
  color: white !important;
  /* Change the text color to ensure visibility */

}

.react-datepicker {
  border: none !important;
}

.react-datepicker__current-month {
  color: #697a8d !important;
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker__day-names {
  color: #697a8d !important;
}

.react-datepicker__day-name {
  color: #697a8d !important;
}

.text-center {
  position: relative;
  height: 100%;
}

.spinner-border {
  display: block;
  position: fixed;
  top: calc(50% - (58px / 2));
  right: calc(50% - (58px / 2));
  color: #696cff;
}

#backdrop {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: rgb(0, 0, 0, 0.2);
}

.action-btn {
  color: #696cff;
}

.have-account {
  text-align: center;
  margin-top: 25px;
}

.modal-dialog-centered {
  align-items: normal !important;
}

@media (min-width: 768px) {
  .modal-dialog-centered {
    min-height: auto;
  }
}

.dropdown-menu.show {
  display: block;
}

.input-date {
  @media only screen and (max-width: 560px) {
    min-width: 108px !important;
  }

  min-width: 200px !important;
}

@media (max-width: 767.98px) {
  .modal .modal-header .btn-close {
    margin: 5px;
  }
}

div:where(.swal2-container) {
  z-index: 9999 !important;
}

.disabled-slot {
  // border-radius: 4px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: #e4e0e0 !important;
}

.remove-slot-btn {
  color: red;
  font-size: 18px;
  border: none;
  padding: 5px;
  margin: 0 !important;
}

.bg-label-void {
  background-color: #e0e0e0 !important;
  color: grey !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.width-fit-content {
  width: fit-content;
}

.phone-number-invalid {
  border-color: #dc3545 !important;
  border-radius: 0.3rem;
  border: 1px solid #ced4da;
}

.react-international-phone-country-selector-button,
.react-international-phone-input {
  min-height: 40px;
}

.flex-grow-1 {
  flex-grow: 1;
}

.margin-right-nev-7 {
  margin-right: -7px;
}

.gift-card-item,
.gift-card-image {
  width: 100%;
}

.remove-item-container {
  padding-bottom: .8rem;
  align-self: flex-end;
}

.gift-card-delete-icon {
  position: absolute;
  right: -12px;
  top: -2px;
  cursor: pointer !important;
  padding: 0px;
}

.check-overlay {
  margin: 0 auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
}

.color-white {
  color: #ffff;
}

.z-index-9999 {
  z-index: 9999;
}

@media only screen and (max-width:991px) {

  .gift-card-item,
  .gift-card-image {
    width: 200px;
  }
}

.timeline-body {
  max-height: 75vh;
  overflow: auto;
}

.rounded-end-0 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-start-0 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}